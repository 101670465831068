<template>
  <page-container class="user-notifications" title="Оповещения">
    <v-sheet class="pa-8">
      <v-form>
        <v-autocomplete
          v-model="formModel.notification_contacts"
          label="Адреса почты для оповещений"
          :items="contactOptions"
          multiple
          hide-details
          dense
          filled
        >
          <template #selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              small
              @click="data.select"
              @click:close="removeSelectedEmail(data.item)"
            >
              {{ data.item.text }}
            </v-chip>
          </template>
        </v-autocomplete>

        <v-checkbox
          v-model="formModel.notify_user"
          label="Уведомлять об изменении статуса избранных участков"
          hide-details
          dense
        />
      </v-form>
    </v-sheet>
  </page-container>
</template>

<script>
  import _ from 'lodash'
  import PendingTasksManagerMixin, { handlePendingTask } from 'geoportal/src/mixins/PendingTasksManagerMixin'

  export default {
    name: 'UserNotificationsView',
    mixins: [PendingTasksManagerMixin],

    data() {
      return {
        initialSettings: {},
        formModel: {},
        userContacts: []
      }
    },

    computed: {
      contactOptions() {
        return this.userContacts.reduce((acc, contact) => {
          if (!contact.email) {
            return acc
          }

          acc.push({
            text: contact.email,
            value: contact.id
          })

          return acc
        }, [])
      },

      handleSettingsChangeDebounced() {
        return _.debounce(this.handleSettingsChange, 1000)
      }
    },

    watch: {
      formModel: {
        handler: 'handleSettingsChangeDebounced',
        deep: true
      }
    },

    created() {
      this.init()
    },

    methods: {
      @handlePendingTask()
      async init() {
        await Promise.all([this.fetchNotificationsSettings(), this.fetchUserContacts()])
      },

      async fetchNotificationsSettings() {
        this.initialSettings = await this.$api.backend.notifications.get_settings()
        this.formModel = _.cloneDeep(this.initialSettings)
      },

      async fetchUserContacts() {
        this.userContacts = await this.$api.backend.user_contacts.get_contacts_by_user({ user_id: this.$auth.user.id })
      },

      removeSelectedEmail(item) {
        const index = this.formModel.notification_contacts.findIndex((item_) => item_.value === item.value)
        this.formModel.notification_contacts.splice(index, 1)
      },

      @handlePendingTask()
      async handleSettingsChange() {
        const formData = _.pickBy(this.formModel, (value, key) => !_.isEqual(value, this.initialSettings[key]))
        if (_.isEmpty(formData)) {
          return
        }

        this.initialSettings = await this.$api.backend.notifications.update_settings(formData)
        this.formModel = _.cloneDeep(this.initialSettings)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .user-notifications {
    .v-sheet {
      width: 800px;
    }
  }
</style>
